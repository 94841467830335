import { CustomRoutes } from "react-admin";
import { Navigate, Route } from "react-router-dom";
import FaqPage from "../../components/Faq/FaqPage";
import { CTD_DASHBOARD } from "../../definition/definition";
import AWSMonthlyCostReport from "../../components/Lists/Accounts/CTD/AWSMonthlyCost";
import ContainerMonthlyCostReport from "../../components/Lists/Accounts/CTD/ContainerMonthlyCost";
import FocusReport from "../../components/Lists/Accounts/CTD/FocusReport";
import StorageMonthlyCostReport from "../../components/Lists/Accounts/CTD/StorageMonthlyCost";
import MarketPlaceReport from "../../components/Lists/Accounts/CTD/MarketPlace";

export const Resources = () => {
  return [
    <CustomRoutes>
      <Route path="/" element={<Navigate to="/ctd/awsorg" />} />
      <Route path="/awsorg" element={<AWSMonthlyCostReport />} />
      <Route path="/container" element={<ContainerMonthlyCostReport />} />
      <Route path="/focus" element={<FocusReport />} />
      <Route path="/storage" element={<StorageMonthlyCostReport />} />
      <Route path="/marketplace" element={<MarketPlaceReport />} />
      <Route path="/faq" element={<FaqPage dashboard={CTD_DASHBOARD} />} />
    </CustomRoutes>,
  ];
};
