import PageHeader from "../../../Header/PageHeader";
import EmbeddedDashboard from "../../../Quicksight/EmbeddedDashboard";

function MarketPlace() {
  return (
    <div>
      <PageHeader title="Marketplace usage" />
      <EmbeddedDashboard dashboardName="CIDMarketPlaceReport" />
    </div>
  );
}

export default MarketPlace;
